import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Form, Input, Label, Checkbox, Field } from '../styleguide/Form';
import Button from '../styleguide/Button';
import receivers from '../utils/receivers_new';
import Search from './Downshift';
import Text from '../styleguide/Text';
import Status from '../styleguide/Status';
import config from '../utils/config';

const SuccessForm = ({ answers, setSentForm }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [region, setRegion] = useState('');
  const [commune, setCommune] = useState('');
  const [county, setCounty] = useState('');
  const [consentEmail, setConsentEmail] = useState('');
  const [consentBasic, setConsentBasic] = useState('');
  const [formError, setFormError] = useState('');

  const handleSelect = (region, commune, county) => {
    setRegion(region);
    setCommune(commune);
    setCounty(county);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = config.profilerUrl;
  
    const [firstname, ...lastnameParts] = name.split(' ');
  
    const hubspotData = {
      email,
      phone,
      firstname,
      lastname: lastnameParts,
      [config.characteristics.goodCharacter]: answers[0],
      [config.characteristics.stableEconomy]: answers[1],
      [config.characteristics.spareBedroom]: answers[2],
      [config.characteristics.speakNorwegian]: answers[3],
      [config.characteristics.goodMentalHealth]: answers[4],
      [config.characteristics.adaptedChild]: answers[5],
      [config.characteristics.workedWithChildren]: answers[6],
      [config.characteristics.state]: region,
      [config.characteristics.city]: commune,
      [config.characteristics.country]: county,
      [config.characteristics.consentBasic]: consentBasic,
      [config.characteristics.consentEmail]: consentEmail,
    };
  
    const hsContext = {
      hutk: Cookies.get('hubspotutk'),
      pageUrl: window.location.href,
      pageName: document.title,
    };
  
    const urlEncodedData = new URLSearchParams(hubspotData).toString();
    const urlEncodedHsContext = new URLSearchParams({
      hs_context: JSON.stringify(hsContext)
    }).toString();
  
    const postData = `${urlEncodedData}&${urlEncodedHsContext}`;
  
    await fetch(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'POST',
      body: postData,
    }).then((response) => {
      if ([200, 204].includes(response.status)) {
        setSentForm(email);
      } else {
        let errorText ='Noe gikk galt ved innsending av skjema. Vennligst prøv igjen ved å oppdatere nettleservinduet, eller vent noen minutter før du prøver på nytt.';
        setFormError(errorText);
      }
    },
    (error) => {
      console.error('FAILED...', error);
    });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Field>
        <Label htmlFor="name">
          <span>
            Navn <span className="required">*</span>
          </span>
        </Label>
        <Input
          type="text"
          label="Navn"
          placeholder="Skriv inn ditt fulle navn"
          name="name"
          id="name"
          required
          withLabelSpace
          onChange={(e) => setName(e.target.value)}
        />
      </Field>

      <Field>
        <Label htmlFor="phone">
          <span>
            Telefonnummer <span className="required">*</span>
          </span>
        </Label>
        <Input
          type="text"
          label="Telefonnummer"
          placeholder="Skriv inn ditt telefonnummer"
          name="phone"
          id="phone"
          required
          withLabelSpace
          onChange={(e) => setPhone(e.target.value)}
        />
      </Field>
      <Field>
        <Label htmlFor="email">
          <span>
            E-post <span className="required">*</span>
          </span>
        </Label>
        <Input
          type="email"
          label="E-post"
          placeholder="Skriv inn din e-postadresse"
          name="email"
          id="email"
          required
          withLabelSpace
          onChange={(e) => setEmail(e.target.value)}
        />
      </Field>
      <Field>
        <Label htmlFor="county">
          <span>
            Kommune <span className="required">*</span>
          </span>
        </Label>
        <Search handleSelect={handleSelect} items={receivers} />
      </Field>

      <Field>
        <Checkbox
          required={true}
          onChange={(e) => setConsentBasic(e.target.checked)}
        >
          Jeg godtar at Stendi oppbevarer de oppgitte personopplysningene i tråd
          med{" "}
          <a
            target="_blank"
            href="https://stendi.no/personvernerklaeringer"
            rel="noopener noreferrer"
          >
            personvernerklæringen
          </a>
          , og at Stendi kan kontakte meg per telefon og/eller e-post.{" "}
          <span className="required">*</span>
        </Checkbox>
      </Field>
      <Field>
        <Checkbox onChange={(e) => setConsentEmail(e.target.checked)}>
          Jeg ønsker å motta relevante nyhetsbrev fra Stendi Fosterhjemtjenester
        </Checkbox>
      </Field>
      <Field>
        <Text>
          <Text element="span" color="primary">
            *
          </Text>
          Feltet må fylles ut.
        </Text>
      </Field>
      {formError && (
        <Field>
          <Status>{formError}</Status>
        </Field>
      )}
      <Field>
        <Button type="submit" fullWidth>
          Send
        </Button>
      </Field>
    </Form>
  );
};

export default SuccessForm;
