import React, { Fragment } from 'react';
import Center from '../styleguide/Center';
import Progresser from './Progresser';
import { VerticalSpacer } from '../styleguide/Spacer';
import SuccessForm from './SuccessForm';
import FormSent from './FormSent';
import { HorizontalSpacer } from '../styleguide/Spacer';
import ContentSizer from '../styleguide/ContentSizer';
import Section from '../styleguide/Section';
import Card from '../styleguide/Card';
import Block from '../styleguide/Block';
import { ButtonExternal } from '../styleguide/Button';
import Text from '../styleguide/Text';
import calculateResult from '../utils/calculateResult';

function Result({ answers, sent, setSentForm, quizData }) {
  const isSuccess = calculateResult(answers);

  const failedTitle = 'Takk for at du deltok!';
  const successTitle = 'Det var en god start!';

  if (!isSuccess) {
    window.history.pushState(null, null, 'x');
  } else {
    window.history.pushState(null, null, 'success');
  }

  const successSubtitle =
    'Svarene dine indikerer at du kan ha gode forutsetninger for å lykkes i et givende fosterhjemsarbeid. Vi vil gjerne bli bedre kjent med deg! Legg igjen din kontaktinfo nedenfor, så vil en av våre dedikerte fagkonsulenter kontakte deg i løpet av kort tid.';

  return (
    <VerticalSpacer>
      <HorizontalSpacer>
        <ContentSizer>
          <Section>
            <Card>
              <Block tighter>
                {sent ? (
                  <FormSent />
                ) : (
                  <Fragment>
                    <Center>
                      <Progresser />
                    </Center>
                    <Text
                      variant="subtitle"
                      color="primary"
                      gutterTop="xlarge"
                      gutterBottom="large"
                      align="center"
                    >
                      {!isSuccess ? failedTitle : successTitle}
                    </Text>
                    {isSuccess && (
                      <Fragment>
                        <Text align="center" gutterBottom="large" width="60%">
                          {successSubtitle}
                        </Text>
                        <SuccessForm
                          quizData={quizData}
                          answers={answers}
                          setSentForm={setSentForm}
                        />
                      </Fragment>
                    )}

                    {!isSuccess && (
                      <Fragment>
                        <Text align="center" gutterBottom="large" width="60%">
                          Basert på svarene dine ser det dessverre ikke ut til
                          at du oppfyller våre kriterier for å kunne bli et
                          Stendi familie- eller beredskapshjem. Du kan lese mer
                          om fosterhjemsarbeidet vårt på{' '}
                          <a
                            target="_blank"
                            href="https://www.stendi.no/barnevern/fosterhjem"
                            rel="noopener noreferrer"
                          >
                            vår nettside.
                          </a>
                        </Text>
                        <Center>
                          <ButtonExternal href="https://stendi.no">
                            Tilbake til Stendi fosterhjemtjenester
                          </ButtonExternal>
                        </Center>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Block>
            </Card>
          </Section>
        </ContentSizer>
      </HorizontalSpacer>
    </VerticalSpacer>
  );
}

export default Result;
