export default {
  gtmId: 'GTM-PKB9DPM',
  profilerUrl: `${process.env.REACT_APP_HUBSPOT_API_URL}/${process.env.REACT_APP_HUBSPOT_FORM_ID_APPLICATION}`,
  characteristics: {
    state: 'state',
    city: 'city',
    country: 'country',
    goodCharacter: 'good_character',
    stableEconomy: 'stable_economy',
    spareBedroom: 'spare_bedroom',
    speakNorwegian: 'speak_norwegian',
    goodMentalHealth: 'good_mental_health',
    adaptedChild: 'adapted_child',
    workedWithChildren: 'worked_with_children',
    consentBasic: 'consent_basic',
    consentEmail: 'consent_email',
  },
};
